import { useEffect, useMemo, useState } from "react";

// react-router components
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Toaster } from "react-hot-toast";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";

// Material Dashboard 2 React routes
//import routes from "routes";
import filteredRoutes from "services/filter-routes";

// Material Dashboard 2 React contexts
import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "contexts/auth.context";

// Images
import {
  default as brandDark,
  default as brandWhite,
} from "assets/images/main-logo.png";

import ForgotPassword from "auth/forgot-password";
import Login from "auth/login";
import ResetPassword from "auth/reset-password";
import axios from "axios";
import ProtectedRoute from "examples/ProtectedRoute";
import UserManagement from "layouts/user-management";
import UserProfile from "layouts/user-profile";
import { setupAxiosInterceptors } from "./services/interceptor";

import Applications from "layouts/apps";
import Audits from "layouts/audits";
import Branches from "layouts/branches/index.js";
import Pillars from "layouts/pillars";
import Subscriptions from "layouts/subscriptions";
import Users from "layouts/users";

import { LinearProgress } from "@mui/material";
import AccessDenied from "components/AccessDenied";
import LoadingBar from "components/Loading/loading-bar";
import { useAuth } from "contexts/auth.context";
import Accounts from "layouts/accounts";
import rolesService from "services/roles-service";
import Reset from "layouts/reset";

export default function App() {
  const authContext = useAuth();

  const [routes, setRoutes] = useState(null);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useEffect(() => {
    async function asyncHelper(cancelToken) {
      const [response, data] = await rolesService.getMyPermissions();
      if (response.ok) {
        authContext.setPermissions(data);
      }
      setRoutes(await filteredRoutes());
    }
    const source = axios.CancelToken.source();
    asyncHelper(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, [authContext.isAuthenticated, authContext.user?.role?.id]);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  const navigate = useNavigate();
  setupAxiosInterceptors(
    () => {
      if (String(window.location.href).includes("/auth/")) {
      } else {
        navigate("/auth/login");
        authContext.logout();
      }
    },
    () => {
      navigate("/access-denied");
    }
  );

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.components) {
        return getRoutes(route.components);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            //exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      {isOnline &&
        routes &&
        (direction === "rtl" ? (
          <CacheProvider value={rtlCache}>
            <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
              <CssBaseline />
              <LoadingBar />
              <LinearProgress
                sx={{
                  position: "fixed",
                  top: 0,
                  width: "calc(100vw - 17px)",
                  height: authContext.loading ? "5px" : 0,
                  left: 0,
                  transition: "all 0.8s ease-in-out",
                }}
                color="info"
              />
              {layout === "dashboard" && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={
                      (transparentSidenav && !darkMode) || whiteSidenav
                        ? brandDark
                        : brandWhite
                    }
                    brandName="IFPC360"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                  {configsButton}
                </>
              )}
              {layout === "vr" && <Configurator />}
              <Routes>
                <Route path="reset" element={<Reset />} />
                <Route path="login" element={<Navigate to="/auth/login" />} />
                {/*<Route
                    path="register"
                    element={<Navigate to="/auth/register" />}
                  />*/}
                <Route
                  path="forgot-password"
                  element={<Navigate to="/auth/forgot-password" />}
                />
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </ThemeProvider>
          </CacheProvider>
        ) : (
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            <LoadingBar />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={
                    (transparentSidenav && !darkMode) || whiteSidenav
                      ? brandDark
                      : brandWhite
                  }
                  brandName="IFPC360"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                {/*<Configurator />
              {configsButton}*/}
              </>
            )}
            {/*layout === "vr" && <Configurator />*/}
            <Routes>
              <Route path="reset" element={<Reset />} />
              <Route
                path="/access-denied"
                element={<AccessDenied type="page" />}
              />
              <Route
                path="/auth/reset-password/:token/:email"
                element={<ResetPassword />}
              />
              <Route path="/auth/login" element={<Login />} />
              {/*<Route path="/auth/register" element={<Register />} />*/}
              <Route
                path="/auth/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                path="/branches/*"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <Branches />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/accounts/*"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <Accounts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/audits/*"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <Audits />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pillars/*"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <Pillars />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/*"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subscriptions/*"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <Subscriptions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/apps/*"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <Applications />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="user-profile"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <UserProfile />
                  </ProtectedRoute>
                }
                key="user-profile"
              />
              <Route
                exact
                path="user-management"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <UserManagement />
                  </ProtectedRoute>
                }
                key="user-management"
              />
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </ThemeProvider>
        ))}

      <Toaster
        position="bottom-right"
        toastOptions={{
          success: {
            duration: 5000,
            style: { fontFamily: "Roboto" },
            iconTheme: {
              primary: "#48c2c3",
              secondary: "white",
            },
          },
          error: {
            duration: 20000,
            style: { fontFamily: "Roboto", fontSize: "1rem" },
            iconTheme: {
              primary: "#dc1b47",
              secondary: "white",
            },
          },
        }}
      />

      {!isOnline && "You are not connected to the internet."}
    </>
  );
}
