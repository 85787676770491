import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function Reset() {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return <Navigate to="/auth/login" />;
}
